/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes, { InferProps } from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  Box,
  DefaultTheme,
  Heading,
  Icon,
  Text,
  makeStyles
} from 'groundkeeper-component-library';

import Link from './Link';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  teaserItem: {
    // width: '33%',
    display: 'block',
    boxSizing: 'border-box',
    listStyle: 'none',
    '& h2': {
      fontSize: '1.0625rem',
      margin: '.5rem1 0 .4rem 0',
      paddingLeft: 0,
      borderLeft: 0
    }
  },
  teaserImage: {
    background: `repeating-linear-gradient(
      135deg,
      #112B4A,
      #112B4A 10px,
      ${theme.color.primary.main} 10px,
      ${theme.color.primary.main} 40px
    );`,
    width: '100%',
    height: '15rem',
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      height: '6rem',
      width: '6rem'
    },
    [theme.mediaQueries.tiny]: {
      height: '10rem',
      '& svg': {
        height: '4rem',
        width: '4rem'
      }
    }
  }
}));

export default function Teaser({
  title,
  text,
  icon,
  href,
  moreLink
}: InferProps<typeof Teaser.propTypes>) {
  const classes = useStyles();
  const router = useRouter();
  const intl = useIntl();
  const [linkHovered, setLinkHovered] = useState(false);

  function handleClickableAreaSmart(e) {
    if (!href) {
      console.warn(
        'Warning: `handleClickableAreaSmart` in `Teaser` Component has ' +
          'detected missing `href`. Area smart behavior is automatically ' +
          'disabled.'
      );
      return;
    }

    switch (e.type) {
      case 'click':
        router.push(href);
      case 'mouseenter':
        setLinkHovered(true);
        break;

      case 'mouseleave':
        setLinkHovered(false);
        break;
    }
  }

  return (
    <article className={classes.teaserItem}>
      <Box
        className={classes.teaserImage}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={handleClickableAreaSmart}
        onMouseEnter={handleClickableAreaSmart}
        onMouseLeave={handleClickableAreaSmart}
      >
        <Icon color="secondary" name={icon} />
      </Box>
      <Heading
        onClick={handleClickableAreaSmart}
        onMouseEnter={handleClickableAreaSmart}
        onMouseLeave={handleClickableAreaSmart}
        type="h2"
      >
        {title}
      </Heading>
      <Box display="inline">
        <Text>{text}</Text>
        <Link
          style={{ textDecoration: linkHovered ? 'underline' : 'none' }}
          href={href}
          showArrow
        >
          {moreLink
            ? moreLink
            : intl.formatMessage({
                id: 'more',
                defaultMessage: 'More'
              })}
        </Link>
      </Box>
    </article>
  );
}

Teaser.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node,
  icon: PropTypes.string,
  href: PropTypes.string,
  moreLink: PropTypes.string
};

Teaser.defaultProps = {
  moreLink: undefined
};
